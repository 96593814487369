export const currency = (amount: number) => {
  return `₹${decimal(amount)}`;
};

export const decimal = (amount: number) => {
  return `${amount?.toFixed(2)}`;
};

export const convertStringToNumber = (value: string) => {
  return Number(value);
};

export const roundToTwoDecimals = (amount: number) => {
  return Math.round(amount * 100) / 100;
};
