import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Product } from '../../types/product';

export const fetchMenuItems = createAsyncThunk(
  'menu/fetchAll',
  async (payload, { rejectWithValue, getState }) => {
    return {
      data: [
        {
          id: '1',
          name: 'Green Tea',
          price: 2.99,
        },
        {
          id: '2',
          name: 'Espresso',
          price: 3.49,
        },
        {
          id: '3',
          name: 'Bottled Water',
          price: 0.99,
        },
        {
          id: '4',
          name: 'Pepsi',
          price: 1.49,
        },
        {
          id: '5',
          name: 'Chocolate Chip Cookie',
          price: 1.99,
        },
        {
          id: '6',
          name: 'Green Tea',
          price: 2.99,
        },
        {
          id: '7',
          name: 'Espresso',
          price: 3.49,
        },
        {
          id: '8',
          name: 'Bottled Water',
          price: 0.99,
        },
        {
          id: '9',
          name: 'Pepsi',
          price: 1.49,
        },
        {
          id: '10',
          name: 'Chocolate Chip Cookie',
          price: 1.99,
        },
      ],
    };
  }
);

const menuItemsSlice = createSlice({
  name: 'menuItems',
  initialState: { items: [] },
  reducers: {
    resetMenuItems: (state, action) => {
      return { items: [] };
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchMenuItems.fulfilled,
      (state: { items: Array<Product> }, action) => {
        state.items = action.payload.data;
      }
    );
  },
});

const { resetMenuItems } = menuItemsSlice.actions;

export { resetMenuItems };
export const menuItemsReducer = menuItemsSlice.reducer;
