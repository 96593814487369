import { toast } from 'react-toastify';

type Notification = {
  message: string;
};

export const successNotification = ({ message = '' }: Notification) => {
  toast.success(message, { autoClose: 1000 });
};

export const infoNotification = ({ message = '' }: Notification) => {
  toast.info(message, { autoClose: 1000 });
};

export const errorNotification = ({ message = '' }: Notification) => {
  toast.error(message, { autoClose: 1000 });
};

export const warningNotification = ({ message = '' }: Notification) => {
  toast.warning(message, { autoClose: 1000 });
};
