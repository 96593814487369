import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Add, Remove } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { AppDispatch } from '../../store';
import { cartItemByProductIdSelector } from '../../store/selectors/cart.selectors';
import {
  addItemToCart,
  removeItemFromCart,
} from '../../store/slices/cart.slice';
import { CartItem } from '../../types/cart-store';
import { infoNotification } from '../notification';

interface IAddToCartProps {
  productId: string;
}

const AddToCart: React.FunctionComponent<IAddToCartProps> = ({ productId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const cartItem: CartItem | undefined = useSelector(
    cartItemByProductIdSelector(productId)
  );
  const addToCart = () => {
    infoNotification({ message: 'Item added to cart' });
    dispatch(addItemToCart(productId));
  };
  const removeFromCart = () => {
    dispatch(removeItemFromCart(productId));
  };
  const isItemAddedToCart = !!cartItem;
  const quantity = cartItem?.quantity ?? 0;
  if (isItemAddedToCart) {
    return (
      <div className="flex flex-row items-center">
        <div
          onClick={removeFromCart}
          className="bg-red-800 text-white rounded-l-md"
        >
          <Remove
            style={{
              fontSize: '44px',
            }}
          />
        </div>
        <Typography variant="h5" className="px-2">
          {quantity}
        </Typography>
        <div
          onClick={addToCart}
          className="bg-green-800 text-white rounded-r-md"
        >
          <Add
            style={{
              fontSize: '44px',
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div onClick={addToCart} className="bg-green-800 text-white">
        <Add
          style={{
            fontSize: '44px',
          }}
        />
      </div>
    </div>
  );
};

export default AddToCart;
