import * as React from 'react';

import { Product } from '../../types/product';
import ProductListItem from './product';

interface IProductListProps {
  products: Array<Product>;
}

const ProductList: React.FunctionComponent<IProductListProps> = ({
  products,
}) => {
  return (
    <div className="flex flex-col flex-wrap md:flex-row">
      {products.map((product: Product) => {
        return <ProductListItem product={product} />;
      })}
    </div>
  );
};

export default ProductList;
