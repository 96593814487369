import thunk, { ThunkDispatch } from 'redux-thunk';

import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import { cartReducer } from './slices/cart.slice';
import { menuItemsReducer } from './slices/menu.slice';

export const rootReducer = combineReducers({
  menu: menuItemsReducer,
  cart: cartReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: {},
  middleware: [thunk],
});

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
