import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { ShoppingCart } from '@mui/icons-material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { styled } from '@mui/material/styles';

import { appRoutes } from '../../appRoutes';

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'fixed',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const actions = [
  {
    icon: <ShoppingCart />,
    name: 'Copy',
    onclick: () => {
      console.log('sm');
    },
  },
];

export default function PlaygroundSpeedDial() {
  const navigate = useNavigate();
  return (
    <div className="md:hidden">
      <StyledSpeedDial
        ariaLabel="shopping-cart"
        icon={<ShoppingCart />}
        onClick={() => {
          navigate(appRoutes.CART);
        }}
        direction={'up'}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </StyledSpeedDial>
    </div>
  );
}
