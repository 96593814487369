import { createTheme } from '@mui/material';

import { HtmlColors } from '../utils/colors';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const AppTheme = createTheme({
  typography: {
    fontFamily: ['Roboto Condensed'].join(','),
  },
  palette: {
    primary: {
      main: HtmlColors.GREEN,
    },
    action: {
      disabledBackground: 'grey',
      disabled: 'white',
    },
  },
});

export default AppTheme;
