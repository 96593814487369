export const HtmlColors = {
  GREEN: '#2A844D',
  ACTIVE_USER_GREEN: '#5BC163',
  RED: '#B54848',
  YELLOW: '#F6B327',
  PINK: 'pink',
  BLUE: 'lightblue',
  GREY: '#333333',
  LIGHT_GREY: '#f9f9f9',
  BLACK: '#111111',
  WHITE: 'white',
  CADET_BLUE: '#479EB9',
  BISQUE: 'bisque',
  VEG_PRODUCT_BG: '#e8f5e8',
  NON_VEG_PRODUCT_BG: '#fee6e6',
  LIGHT_BLUE: 'rgb(229, 246, 253)',
  LIGHT_PURPLE: 'rgb(181 163 214)',
  YELLOW_GREEN: '#c5e384',
  METALLIC_YELLOW: '#fdcc0d',
  ORANGE: 'orange',
  MAGENTA: '#5BC49B',
};
