import * as React from 'react';

import { Logout } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

import ProductsList from './products-list';

interface IAdminHomeProps {}

const AdminHome: React.FunctionComponent<IAdminHomeProps> = (props) => {
  return (
    <div className="lg:m-8 m-4">
      <div className="flex flex-row justify-between my-8">
        <p className="text-3xl">Welcome Admin,</p>
        <IconButton aria-label="delete">
          <Logout color="error" />
        </IconButton>
      </div>
      <ProductsList />
    </div>
  );
};

export default AdminHome;
