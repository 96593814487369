import * as React from 'react';
import { useSelector } from 'react-redux';

import { ShoppingCart } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { cartItemsSelector } from '../../store/selectors/cart.selectors';
import { CartItem } from '../../types/cart-store';
import CartItemComp from './cart-item';

interface ICartItemsProps {}

const CartItems: React.FunctionComponent<ICartItemsProps> = (props) => {
  const cartItems = useSelector(cartItemsSelector);
  if (cartItems.length === 0) {
    return (
      <div className="flex flex-col items-center w-full text-center mt-24 md:mt-2">
        <Typography variant="h3">Cart is empty</Typography>
        <ShoppingCart
          style={{
            fontSize: '96px',
          }}
        />
      </div>
    );
  }
  return (
    <div className="flex flex-col w-full items-center">
      {cartItems.map((item: CartItem) => {
        return <CartItemComp item={item} />;
      })}
    </div>
  );
};

export default CartItems;
