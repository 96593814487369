import * as React from 'react';

import { Facebook, Instagram, LocationOn } from '@mui/icons-material';
import { Typography } from '@mui/material';

interface IFooterProps {}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  const instagramClick = () => {
    window.open(
      'https://www.instagram.com/samruddhi_cricket_grounds/?hl=en',
      '_blank'
    );
  };
  return (
    <div
      className="bg-black text-white p-8 flex flex-col md:flex-row md:justify-between items-center"
      style={{ bottom: '0' }}
    >
      <div className="flex flex-col md:flex-row items-center">
        <LocationOn fontSize="large" className="md:mr-2" />
        <div className="text-center md:text-start">
          <Typography>Opposite Silaplu Manufacturing,</Typography>
          <Typography>Indrareddynagar To Mirzaguda Road,</Typography>
          <Typography>Next To ORR Service Road, Vattinagulapalle,</Typography>
          <Typography>Telangana 500075</Typography>
        </div>
      </div>
      <div className="mt-6 md:mt-0 flex flex-row items-center">
        <Typography className="pr-4 hidden md:block">Follow us:</Typography>
        <Instagram fontSize="large" onClick={instagramClick} />
        <Facebook fontSize="large" />
      </div>
    </div>
  );
};

export default Footer;
