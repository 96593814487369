import * as React from 'react';

import { Card, Typography } from '@mui/material';

import { Product } from '../../types/product';
import { currency } from '../../utils/currencyUtils';
import AddToCart from '../add-to-cart';

interface IProductProps {
  product: Product;
}

const ProductListItem: React.FunctionComponent<IProductProps> = ({
  product,
}) => {
  return (
    <Card
      className="m-2 flex flex-col w-96"
      //   sx={{
      //     '& > :not(style)': { width: 300 },
      //   }}
    >
      <img src="images/chai.jpg" alt="productImage" />
      <div className="flex flex-row justify-between items-center p-2">
        <div>
          <Typography variant="h6">{product.name}</Typography>
          <Typography variant="h4">{currency(product.price)}</Typography>
        </div>
        <div>
          <AddToCart productId={product.id} />
        </div>
      </div>
    </Card>
  );
};

export default ProductListItem;
