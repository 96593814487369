import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CloseRounded, RemoveCircle } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';

import ConfirmDialog from '../../components/confirm-dialog';
import { productsSelector } from '../../store/selectors/menu.selectors';
import { Product } from '../../types/product';
import AddNewProduct from './add-new-product';

//nested data is ok, see accessorKeys in ColumnDef below

const ProductsList = () => {
  const products = useSelector(productsSelector);
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<Product>[]>(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Product Name',
        size: 150,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 150,
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={products}
      enableRowActions
      enableEditing
      positionActionsColumn="last"
      renderTopToolbarCustomActions={({ table }) => {
        return <AddNewProduct />;
      }}
      renderRowActionMenuItems={({ closeMenu }) => [
        <MenuItem key={0} sx={{ m: 0 }}>
          <ConfirmDialog
            title="Confirm?"
            description="Are you sure you want to delete this product forever? This action is irreversable."
            onConfirm={() => {}}
          >
            <div className="flex flex-row items-center">
              <ListItemIcon>
                <CloseRounded />
              </ListItemIcon>
              Delete
            </div>
          </ConfirmDialog>
        </MenuItem>,
        <MenuItem key={1} sx={{ m: 0 }}>
          <ConfirmDialog
            title="Confirm?"
            description="Are you sure you want to disable this product?"
            onConfirm={() => {}}
          >
            <div className="flex flex-row items-center">
              <ListItemIcon>
                <RemoveCircle />
              </ListItemIcon>
              Disable
            </div>
          </ConfirmDialog>
        </MenuItem>,
      ]}
    />
  );
};

export default ProductsList;
