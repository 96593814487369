import * as React from 'react';

import { TextField } from '@mui/material';

interface IFiltersProps {}

const Filters: React.FunctionComponent<IFiltersProps> = (props) => {
  return (
    <div className="w-full">
      <TextField fullWidth variant="standard" placeholder="Search products" />
    </div>
  );
};

export default Filters;
