import * as React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import { productSelector } from '../../store/selectors/menu.selectors';
import { CartItem } from '../../types/cart-store';
import { Product } from '../../types/product';
import { currency } from '../../utils/currencyUtils';
import AddToCart from '../add-to-cart';

interface IOrderItemProps {
  item: CartItem;
}

const CartItemComp: React.FunctionComponent<IOrderItemProps> = ({ item }) => {
  const { productId, quantity } = item;
  const productInfo: Product | undefined = useSelector(
    productSelector(productId)
  );
  if (!productInfo) {
    return null;
  }
  const { name, price } = productInfo;
  return (
    <div className="flex flex-row justify-between items-center m-2 p-2 rounded-md w-full border-b-2">
      <div className="flex flex-col md:flex-row md:items-center">
        <img
          src="images/chai.jpg"
          alt="productImage"
          className="rounded-lg m-2"
          style={{
            width: '100px',
          }}
        />
        <div className="flex flex-row md:flex-col items-center justify-between">
          <p className="text-2xl font-bold">{name}</p>
          <Typography variant="h6">{currency(price)}</Typography>
        </div>
      </div>
      <AddToCart productId={productId} />
      <div className="flex flex-col justify-between items-end ">
        <Typography variant="h4">{currency(price * quantity)}</Typography>
      </div>
    </div>
  );
};

export default CartItemComp;
