import './App.css';

import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { appRoutes } from './appRoutes';
import Products from './components/products';
import AdminHome from './screens/admin/admin-home';
import AdminLogin from './screens/admin/admin-login';
import Cart from './screens/cart';
import Home from './screens/home';
import { AppDispatch } from './store';
import { fetchMenuItems } from './store/slices/menu.slice';

function App() {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchMenuItems());
  });
  return (
    <Fragment>
      <Helmet>
        <title>{'Samruddhi Cricket Grounds'}</title>
      </Helmet>

      <Routes>
        <Route path={appRoutes.HOME} element={<Home />}>
          <Route index element={<Products />} />
          <Route path={appRoutes.CART} element={<Cart />} />
        </Route>
        <Route path={appRoutes.ADMIN}>
          <Route index element={<AdminHome />} />
          <Route path={appRoutes.ADMIN_LOGIN} element={<AdminLogin />} />
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;
