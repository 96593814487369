import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { successNotification } from '../../components/notification';

interface IAddNewProductProps {}

const AddNewProduct: React.FunctionComponent<IAddNewProductProps> = (props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSave = () => {
    successNotification({ message: 'Product added successfully' });
    handleClose();
  };
  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Add New Product
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Product</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 0.5 },
            }}
            noValidate
            autoComplete="off"
            className="bg-white p-8 md:m-4"
          >
            <TextField id="name" label="Name" fullWidth variant="outlined" />
            <TextField id="price" label="Price" fullWidth variant="outlined" />
            <TextField id="image" fullWidth variant="outlined" type="file" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewProduct;
