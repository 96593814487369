import * as React from 'react';

import { Box, Button, TextField } from '@mui/material';

interface IAdminLoginProps {}

const AdminLogin: React.FunctionComponent<IAdminLoginProps> = (props) => {
  return (
    <div
      className="flex flex-col items-center justify-center login-bg-image"
      style={{
        height: '100vh',
      }}
    >
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 0.5 },
        }}
        noValidate
        autoComplete="off"
        className="bg-white p-8 md:m-4"
      >
        <p className="flex flex-row items-center justify-center self-center text-3xl">
          Admin Login
        </p>
        <TextField
          fullWidth
          label="Email"
          id="email"
          placeholder="Enter your email"
        />

        <TextField
          fullWidth
          label="Password"
          id="password"
          type="password"
          placeholder="Enter your password"
        />
        <Button color="primary" variant="contained" fullWidth>
          Submit
        </Button>
      </Box>
    </div>
  );
};

export default AdminLogin;
