import { CartItem } from '../../types/cart-store';
import { StoreState } from '../../types/store-state';

export const cartSelector = (state: StoreState) => state.cart;

export const cartItemsSelector = (state: StoreState) => state.cart.items;

export const cartItemByProductIdSelector =
  (productId: string) => (state: StoreState) =>
    state.cart.items.find((item: CartItem) => item.productId === productId);
