import { createSlice } from '@reduxjs/toolkit';

import { CartItem, CartStore } from '../../types/cart-store';

const initialState: CartStore = {
  items: [],
  customer: {
    name: '',
    phone: '',
  },
  total: 0,
};
const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    resetCart: (state, action) => {
      return initialState;
    },
    addItemToCart: (state, action) => {
      const payload = action.payload;
      const existingProductIndex = state.items.findIndex(
        (item: CartItem) => item.productId === payload
      );
      if (existingProductIndex !== -1) {
        state.items[existingProductIndex].quantity += 1;
      } else {
        state.items.push({
          productId: payload,
          quantity: 1,
        });
      }
    },
    removeItemFromCart: (state, action) => {
      const payload = action.payload;
      const existingProductIndex = state.items.findIndex(
        (item: CartItem) => item.productId === payload
      );
      if (
        existingProductIndex !== -1 &&
        state.items[existingProductIndex].quantity > 1
      ) {
        state.items[existingProductIndex].quantity -= 1;
      } else {
        state.items.splice(existingProductIndex, 1);
      }
    },
  },
  extraReducers(builder) {},
});

const { resetCart, addItemToCart, removeItemFromCart } = cartSlice.actions;

export { resetCart, addItemToCart, removeItemFromCart };
export const cartReducer = cartSlice.reducer;
