import * as React from 'react';
import { useSelector } from 'react-redux';

import { productsSelector } from '../../store/selectors/menu.selectors';
import Filters from './filters';
import ProductList from './product-list';

interface IProductsProps {}

const Products: React.FunctionComponent<IProductsProps> = (props) => {
  const products = useSelector(productsSelector);
  return (
    <div className="m-8 flex flex-col items-center justify-around">
      <Filters />
      <ProductList products={products} />
    </div>
  );
};

export default Products;
