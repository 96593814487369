import { Product } from '../../types/product';
import { Menu, StoreState } from '../../types/store-state';

export const menuSelector = (state: StoreState): Menu => state.menu;

export const productsSelector = (state: StoreState): Array<Product> =>
  state.menu.items;

export const productSelector =
  (productId: string) =>
  (state: StoreState): Product | undefined =>
    state.menu.items.find((item: Product) => item.id === productId);
