import * as React from 'react';
import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';

import { ShoppingCart } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { appRoutes } from '../appRoutes';
import Footer from '../components/footer';
import Products from '../components/products';
import SpeedDials from '../components/speed-dials';
import AdminHome from './admin/admin-home';
import AdminLogin from './admin/admin-login';
import Cart from './cart';

interface IHomeProps {
  children?: any;
}

const Home: React.FunctionComponent<IHomeProps> = (props) => {
  const navigate = useNavigate();
  const openCart = () => {
    navigate(appRoutes.CART);
  };

  return (
    <div
      className="flex flex-col justify-between"
      style={{
        height: '100vh',
      }}
    >
      <div>
        <div className="flex flex-row justify-between p-8 text-3xl md:text-6xl text-center md:text-left text-green-900">
          <NavLink to={appRoutes.HOME}>Samruddhi Cricket Grounds</NavLink>
          <div className="hidden md:block">
            <IconButton onClick={openCart}>
              <ShoppingCart color="primary" fontSize="large" />
            </IconButton>
          </div>
        </div>
        <div
          className="home-bg-image hidden md:block"
          style={{
            minHeight: '400px',
          }}
        ></div>
        <SpeedDials />
        <Routes>
          <Route index element={<Products />} />
          <Route path={appRoutes.CART} element={<Cart />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
