import * as React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import CartItems from '../components/cart/cart-items';
import Price from '../components/price';
import { cartItemsSelector } from '../store/selectors/cart.selectors';
import { productsSelector } from '../store/selectors/menu.selectors';
import { CartItem } from '../types/cart-store';
import { Product } from '../types/product';
import { currency } from '../utils/currencyUtils';

interface ICartProps {}

const Cart: React.FunctionComponent<ICartProps> = (props) => {
  const cartItems = useSelector(cartItemsSelector);
  const products = useSelector(productsSelector);
  const productsMap = React.useMemo(() => {
    return products.reduce((acc: Record<string, Product>, product: Product) => {
      return {
        ...acc,
        [product.id]: product,
      };
    }, {});
  }, [products.length]);
  const cartTotal = React.useMemo(() => {
    return cartItems.reduce((acc: number, cartItem: CartItem) => {
      return (
        acc + (productsMap[cartItem.productId]?.price ?? 0) * cartItem.quantity
      );
    }, 0);
  }, [cartItems]);
  return (
    <div className="items-center m-2 md:m-16">
      <div className="flex flex-col ">
        <div className="flex flex-row items-center justify-between md:justify-end border-green-900 border-b-2">
          <Typography variant="h4" className="pr-8">
            Your Cart Total
          </Typography>
          <Typography variant="h2">{currency(cartTotal)}</Typography>
        </div>

        <div className="flex flex-col items-center w-full md:flex-row">
          <CartItems />
        </div>
      </div>
    </div>
  );
};

export default Cart;
